import React from 'react';
import styled from '@emotion/styled';
import { colors, fontFamilies } from '../baseStyle/Variables';
import FirstProgress from './first-progress';
import SecondProgress from './second-progress';
import ThirdProgress from './third-progress';

const CHECK_ELIGIBILITY: string[] = ['BasicInfo', 'BasicsContinued', 'BillingAddress', 'BillingAddressErrors', 'BillingAddressLoading', 'NoAccountFound'];

const PAYMENT_DETAILS: string[] = [
  'LeaseApplication.BankDetails',
  'LeaseApplication.DebitCardEntry',
  'LeaseApplication.DebitCardError',
  'LeaseApplication.PayFrequency',
  'LeaseApplication.PaymentSelection.PaymentSelectionInit',
  'LeaseApplication.PaymentSelection.PaymentSelectionStandard',
  'LeaseApplication.PaymentSelection.PaymentSelectionEdit',
  'LeaseApplication.PaymentSelection.FetchLinkToken',
  'LeaseApplication.PaymentReview',
  'LeaseApplication.ShippingAddress',
  'VirtualCardApplication.BankDetails',
  'VirtualCardApplication.CartSummary',
  'VirtualCardApplication.CartTotal',
  'VirtualCardApplication.DebitCardEntry',
  'VirtualCardApplication.ItemEntry',
  'VirtualCardApplication.PayFrequency',
  'DebitCardHardDecline',
  'DebitCardSoftDecline',
];

const REVIEW_AND_PAY: string[] = [
  'LeaseApplication.ContractSummary',
  'LeaseApplication.LeaseAgreement',
  'VirtualCardApplication.ContractSummary',
  'VirtualCardApplication.LeaseAgreement',
  'VirtualCardApplication.VirtualCardPage',
  'LoanApplication.AutoPayEnrollment',
  'LoanApplication.PaymentSchedule',
];

// // TODO: ERK what about virtual card pages?
// // ItemEntry = 'ItemEntry',
// 'VirtualCardApplication.ItemEntry',
// // CartSummary = 'CartSummary',
// 'VirtualCardApplication.CartSummary'
// // CartTotal = 'CartTotal',
// 'VirtualCardApplication.CartTotal'
// // VirtualCardPage = 'VirtualCardPage',
// 'VirtualCardApplication.VirtualCardPage'

interface ProgressBarProps {
  currentNode: [string] | undefined;
}

const ProgressBarContainer = styled.div`
  font-family: ${fontFamilies.interLight};
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.5rem;
`;

const HeaderBottom = styled.div`
  display: flex;
  align-items: center;
  height: 14px;
  width: 100%;
`;
const GreyLine = styled.div`
  height: 1px;
  background-color: ${colors.lightGrey};
  width: 100%;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ currentNode }: ProgressBarProps) => {
  const nodeSelected = !!(currentNode && currentNode.length);
  const nodeString = nodeSelected ? currentNode[currentNode.length - 1] : '';

  const sectionHighlighted = {
    eligibility: false,
    details: false,
    review: false,
  };

  sectionHighlighted.eligibility = CHECK_ELIGIBILITY.includes(nodeString);
  sectionHighlighted.details = PAYMENT_DETAILS.includes(nodeString);
  sectionHighlighted.review = REVIEW_AND_PAY.includes(nodeString);

  const shouldShowProgressBar = () => {
    const showOnSection = sectionHighlighted.eligibility || sectionHighlighted.details || sectionHighlighted.review;
    return showOnSection && !!nodeSelected;
  };

  return shouldShowProgressBar() ? (
    <>
      <ProgressBarContainer role="region" aria-label="Progress Bar">
        <FirstProgress progressText={'Check eligibility'} isHighlighted={sectionHighlighted}></FirstProgress>
        <SecondProgress progressText={'Additional details'} isHighlighted={sectionHighlighted}></SecondProgress>
        <ThirdProgress progressText={'Review & checkout'} isHighlighted={sectionHighlighted}></ThirdProgress>
      </ProgressBarContainer>
    </>
  ) : (
    <HeaderBottom>
      <GreyLine />
    </HeaderBottom>
  );
};

export default ProgressBar;
